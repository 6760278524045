<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-loading="state.loaderEnabled">

          <div class="card-header border-0 pt-6" style="display: flex; justify-content: space-between; align-items: stretch; flex-wrap: nowrap; min-height: 70px; padding: 0 2.25rem; background-color: transparent; flex-direction: row;">
            <el-divider content-position="left"><h2>Règles Firewall L3</h2></el-divider>          
            
            <template v-if="state.editMode" class="mb-4">
              <button v-if="state.displayBtns && !state.applyLinks" class="btn btn-light-danger btn-small mx-2" style="width: 220px;" @click="state.myList=[];buildList()">Annuler</button>
              <button v-if="state.displayBtns && !state.applyLinks" class="btn btn-success btn-small mx-2" @click="validFirewall()">Sauvegarder et appliquer aux {{ state.mNetworksLink.length }} réseaux liés</button>
            </template>

            <SearchAndFilter
              v-if="state.initialMyList && !state.loaderEnabled && !state.editMode"
              :listInitial="state.initialMyList"
              :filters="tableFilters"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
            ></SearchAndFilter>

        </div>
        <div class="card-body pt-0">

          <Datatable
            v-if="state.myList && state.myList.length > 0 && !state.editMode"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="false"
            :rows-per-page="100"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="table-small-td"
          >
            <template v-slot:cell-id="{ row: data }">      
              <span class="text-gray-700">{{ data.id+1 }}</span>
            </template>
            <template v-slot:cell-policy="{ row: data }"><div class="mx-2 text-gray-600"><div style="width: 90px">{{ data.policy == "allow" ? '✅ Allow' : '⛔ Deny'}}</div></div></template>
            <template v-slot:cell-comment="{ row: data }"><div class="mx-2 text-dark">{{ data.comment }}</div></template>

            <template v-slot:cell-srcPort="{ row: data }">      
              <span class="text-gray-600">{{ data.srcPort }}</span>
            </template>

            <template v-slot:cell-destPort="{ row: data }">      
              <div class="text-gray-600" style="width: 180px;">{{ data.destPort }}</div>
            </template>

            <template v-slot:cell-srcCidr="{ row: data }">      
              <div class="mx-2 text-dark p-2" >
                <template v-for="(field, i) in fieldCIDREdit(data.srcCidr)" effect="light" :key="i"> 
                  <div v-if="field.type=='GRP'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='OBJ'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='VLAN'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='FREE'" class='el-tag--info el-tag--plain el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                </template>
              </div>
            </template>

            <template v-slot:cell-destCidr="{ row: data }">
              <div class="mx-2 text-dark p-2">
                <template v-for="(field, i) in fieldCIDREdit(data.destCidr)" effect="light" :key="i"> 
                  <div v-if="field.type=='GRP'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='OBJ'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='VLAN'" class='el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                  <div v-if="field.type=='FREE'" class='el-tag el-tag--info el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                </template>
              </div>              
            </template>

            <template v-slot:cell-protocol="{ row: data }"><div class="mx-2 text-dark">{{ data.protocol.toUpperCase() }}</div></template>
          </Datatable>
         

          <template  v-if="state.editMode && !state.applyLinks">

            <button class="btn btn-secondary btn-small mx-2" style="width: 220px;" @click="addEmptyRowAtStart()">Ajouter une règle</button>

            <el-checkbox v-model="state.specRules" label="Gestion Groupes complémentaires" size="large" class="ms-4"/>

            <el-select v-if="state.specRules" v-model="state.displayGrp.id" class="mx-3" size="small" style="width: 300px;" >
                <el-option label="Afficher toutes les règles" value="all" />
                <el-option v-for="(grp, i) in state.groupeFirewall" :key="i" :label="`Affichage du Groupe ${grp.grp_label}`" :value="grp.grp_seq" />
            </el-select>

            <button v-if="state.specRules && state.displayGrp.id !='all' && state.displayGrp.id != null " class="btn btn-secondary btn-small mx-2" @click="displayGrpLinkNetwork()">Réseaux Liés</button>

            <button v-if="state.specRules && state.displayGrp.id !='all' && state.displayGrp.id != null " class="btn btn-secondary btn-small mx-2" @click="openAddEditGrp(state.displayGrp.id)" title="Modifier le groupe">✏️</button>

            <button v-if="state.specRules" class="btn btn-secondary btn-small mx-2" @click="openAddEditGrp(0,'')" title="Ajouter un nouveau groupe">+</button>

            <hr />

          </template>

          <el-alert v-if="state.errorsTests"  title="Erreur" :description="state.errorsTests" show-icon type="error" class="mt-4 fs-2"/>

          <div v-if="state.applyLinks">
            
            <el-progress :text-inside="true" :stroke-width="26" :percentage="state.linksStep" class="mt-4 mb-4" />
            <center>
              Application des règles firewall sur les {{ state.mNetworksLink.length }} réseaux liés, veuillez patientez, svp.
            </center>

          </div>
          
          
          <div class="table-responsive" v-if="!state.applyLinks">
            <table class="table table-striped" v-if="!state.loaderEnabled && state.editMode">
              <thead class="thead-dark">
                <tr style="border-bottom: 2px solid #ececec">
                  <!-- <th scope="col"></th>-->
                  <th scope="col"><b>TYPE</b></th>
                  <th scope="col"><b>DÉSIGNATION</b></th>
                  <th scope="col"><b>PROTOCOL</b></th>
                  <th scope="col"><b>RÈGLES SOURCES</b></th>
                  <th scope="col"><b>RÈGLES DESTINATIONS</b></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <draggable v-model="state.myList" tag="tbody" item-key="name"  @change="dropRule()">
                <template #item="{ element }">
                  <tr disabled="disabled" style="border-bottom: 1px dashed #ececec; cursor: move; opacity: 0.4" :style="`opacity:${returnOpacityFilterGRP(element)}`" >
                    <!--
                    <td scope="row" style="vertical-align: middle; font-size: 1.45rem;">
                      &nbsp;&nbsp;↕️ <span class="fs-6">{{ element.id+1 }}</span>
                    </td>
                    -->
                    <td scope="row" style="vertical-align: middle;">
                      <div class="text-dark" style="min-width: 110px; max-width: 110px; width: 110px;" >
                        <el-select v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].policy" @change="state.displayBtns=true;" size="small" :disabled="testFilterGRP(element)">
                          <el-option label="✅ Allow" value="allow" />
                          <el-option label="⛔ Deny" value="deny" />
                        </el-select>

                        <el-select v-if="state.specRules" v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].knum_groupe" 
                            class="mt-2" size="small" style="width: 110px;" :disabled="testFilterGRP(element)" @change="state.displayBtns=true;">
                            <el-option v-for="(grp, i) in state.groupeFirewall" :key="i" :label="`${grp.grp_label}`" :value="grp.grp_seq" />
                        </el-select>

                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <div class="text-dark">
                        <el-input size="small" v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].comment" @input="state.displayBtns=true;" type="text" autosize placeholder="Saisir la désignation" style="min-width: 200px;" :disabled="testFilterGRP(element)"/>
                      </div>                    
                    </td>
                    <td style="vertical-align: middle;">
                      <div class="text-dark" style="min-width: 100px; max-width: 100px; width: 100px;">
                        <el-select size="small" v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].protocol" @change="state.displayBtns=true;" :disabled="testFilterGRP(element)">
                          <el-option label="TCP" value="tcp" />
                          <el-option label="UDP" value="udp" />
                          <el-option label="ICMPv4" value="icmp" />
                          <el-option label="ICMPv6" value="icmp6" />
                          <el-option label="Any" value="any" />
                        </el-select>
                      </div>
                    </td>

                    <td>

                      <div class="text-dark" style="display: flex; align-items: baseline;" >
                        <div class="me-2 text-dark" style="min-width: 120px; max-width: 120px; width: 120px;" >
                          <el-input size="small" v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].srcPort" @input="state.displayBtns=true;" placeholder="Port(s)*" type="text" autosize class="m-2 text-center" :disabled="testFilterGRP(element)"/>
                        </div>

                          <el-dropdown :disabled="testFilterGRP(element)">
                            <div class='ms-3 me-2 mx-1 mt-1 fs-1' style="width:20px; display: inline; color: #4486ff; cursor: pointer;">+</div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item disabled>Ajouter une source</el-dropdown-item>     
                                <el-dropdown-item divided @click="promptObj('source',element.id)">Objet</el-dropdown-item>
                                <el-dropdown-item @click="promptVLAN('source',element.id)">VLAN</el-dropdown-item>
                                <el-dropdown-item @click="promptObjGroup('source',element.id)">Object Group</el-dropdown-item>
                                <el-dropdown-item @click="promptIPV4('source',element.id)">CIDR (ipv4)</el-dropdown-item>
                                <el-dropdown-item @click="promptIPV6('source',element.id)">CIDR (ipv6)</el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>

                          <div style="max-width:350px;">
                          <template v-for="(field, i) in fieldCIDREdit(element.srcCidr)" effect="light" :key="i"> 
                            <div v-if="field.type=='GRP'" @click="delElement('SRC',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='OBJ'" @click="delElement('SRC',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='VLAN'" @click="delElement('SRC',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='FREE'" @click="delElement('SRC',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--info el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                          </template>
                          <div v-if="!fieldCIDREdit(element.srcCidr)">⚠️ La source ne peut être vide</div>
                        </div>
                      
                      </div>

                    </td>
                    <td>

                      <div class="text-dark" style="display: flex; align-items: baseline; "> <!-- background: #a2a2a214 -->                    
                        <div class="me-2 text-dark" style="min-width: 145px; max-width: 145px; width: 145px; display: flex;" >
                          <textarea placeholder="Port(s)*" v-model="state.myList[state.myList.findIndex(el => el.id == element.id)].destPort"
                            @input="state.displayBtns=true;" class="m-2 text-center el-textarea__inner" style="height: 38px" :disabled="testFilterGRP(element)">
                          </textarea>
                        </div>

                        <el-dropdown :disabled="testFilterGRP(element)">
                          <div class='ms-3 me-2 mx-1 mt-1 fs-1' style="width:20px; display: inline; color: #4486ff; cursor: pointer;">+</div>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item disabled>Ajouter une destination</el-dropdown-item>                      
                              <el-dropdown-item divided @click="promptObj('destination',element.id)">Objet</el-dropdown-item>
                              <el-dropdown-item @click="promptVLAN('destination',element.id)">VLAN</el-dropdown-item>
                              <el-dropdown-item @click="promptObjGroup('destination',element.id)">Object Group</el-dropdown-item>
                              <el-dropdown-item @click="promptIPV4('destination',element.id)">CIDR (ipv4)</el-dropdown-item>
                              <el-dropdown-item @click="promptIPV6('destination',element.id)">CIDR (ipv6)</el-dropdown-item>
                              <el-dropdown-item @click="promptFree('destination',element.id)">Domain</el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                          
                        <div style="max-width:350px;">
                          <template v-for="(field, j) in fieldCIDREdit(element.destCidr)" effect="light" :key="j"> 
                            <div v-if="field.type=='GRP'" @click="delElement('DEST',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='OBJ'" @click="delElement('DEST',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='VLAN'" @click="delElement('DEST',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--primary el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                            <div v-if="field.type=='FREE'" @click="delElement('DEST',element.id, field.rawValue, element )" class='firewall-field el-tag el-tag--info el-tag--light mx-1 mt-1'>{{ field.value }}</div>
                          </template>
                          <div v-if="!fieldCIDREdit(element.destCidr)">⚠️ La destination ne peut être vide</div>
                        </div>

                      </div>  

                    </td>

                    <td style="vertical-align: middle;">
                      <div style="min-width: 50px; max-width: 50px; width: 50px; margin-right: 10px;" >
                        <el-popconfirm title="Validez-vous la suppression de la règle ?" 
                                      confirm-button-text="Oui, la supprimer"  
                                      cancel-button-text="Non, annuler la suppression" 
                                      @confirm="delLignes(element.id);"
                                      :disabled="testFilterGRP(element)"
                                      >
                          <template #reference>
                            <button class="btn btn-sm btn-white mx-1">❌</button>
                          </template>
                        </el-popconfirm>
                      </div>
                    </td>

                  </tr>
                </template>
              </draggable>
            </table>
          </div>

        </div>
      </div>
    </transition>

    <el-dialog v-model="state.drawerVLAN" title="Ajout d'un VLAN" width="1000">
      <SearchAndFilter
              v-if="state.gVlansInit && state.drawerVLAN"
              :listInitial="state.gVlansInit"
              :filters="[]"
              @returnSearch="state.gVlans = $event"
              @returnLoadingDatatable="state.loadingDatatableVLAN = $event"
      ></SearchAndFilter>
     
      <Datatable
            v-if="state.gVlans && state.gVlans.length > 0"
            :table-data="state.gVlans"
            :table-header="tableHeaderVLAN"
            :enable-items-per-page-dropdown="false"
            :rows-per-page="100"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatableVLAN"
            class="table-small-td"
            @click-function="selectLineVLAN($event)"
          >
            <template v-slot:cell-id="{ row: data }">
              <div class="mx-2 text-gray-900">
                <el-checkbox @change="selectLineVLAN(data)" v-model="data.select" :label="data.id" size="large"  class="text-gray-900" />
              </div>              
            </template>
            <template v-slot:cell-name="{ row: data }">
              <div class="mx-2 text-gray-900">
                {{ data.name }}
              </div>              
            </template>
            
            <template v-slot:cell-applianceIp="{ row: data }">
              <div class="mx-2 text-gray-900">
                {{ data.applianceIp }}
              </div>              
            </template>
            
            <template v-slot:cell-subnet="{ row: data }">
              <div class="mx-2 text-gray-700">
                {{ data.subnet }}
              </div>
            </template>

          </Datatable>  

          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="state.drawerVLAN = false">Annuler</el-button>
              <el-button class="btn btn-primary" @click="ajoutVLAN()">Ajouter le (ou les) VLAN(s)</el-button>
            </span>
          </template>
          
    </el-dialog>

    <el-dialog v-model="state.drawerObj" title="Ajout d'un Objet" width="1000">
      <SearchAndFilter
              v-if="state.gObjectsInit && state.drawerObj"
              :listInitial="state.gObjectsInit"
              :filters="[]"
              @returnSearch="state.gObjects = $event"
              @returnLoadingDatatable="state.loadingDatatableObj = $event"
      ></SearchAndFilter>
      <div style="max-height: 400px;  overflow-y: auto;">
        <Datatable
              v-if="state.gObjects && state.gObjects.length > 0"
              :table-data="state.gObjects"
              :table-header="tableHeaderObj"
              :enable-items-per-page-dropdown="false"
              :rows-per-page="100"
              empty-table-text="Aucune ligne à afficher."
              :key="state.loadingDatatableObj"
              class="table-small-td"
              @click-function="selectLineObj($event)"
            >
              <template v-slot:cell-name="{ row: data }">
                <div class="mx-2 text-gray-900">
                  <el-checkbox @change="selectLineObj(data)" v-model="data.select" :label="data.name" size="large"  class="text-gray-900" />
                </div>              
              </template>
            
              <template v-slot:cell-cidr="{ row: data }">
                <div class="mx-2 text-gray-900">
                  {{ data.cidr }}
                </div>              
              </template>
              
              <template v-slot:cell-category="{ row: data }">
                <div class="mx-2 text-gray-700">
                  {{ data.category }}
                </div>
              </template>

            </Datatable>    
          </div> 
          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="state.drawerObj = false">Annuler</el-button>
              <el-button v-if="state.gObjects && state.gObjects.filter((i)=>i.select).length > 0" class="btn btn-primary" @click="ajoutObj()">Ajouter le (ou les) objet(s)</el-button>
            </span>
          </template>
          
    </el-dialog>

    <el-dialog v-model="state.drawerObjGroup" title="Ajout d'un groupe d'objets" width="1000">
      <SearchAndFilter
              v-if="state.gObjectsGroupInit && state.drawerObj"
              :listInitial="state.gObjectsGroupInit"
              :filters="[]"
              @returnSearch="state.gObjectGroup = $event"
              @returnLoadingDatatable="state.loadingDatatableObjGroup = $event"
      ></SearchAndFilter>
      <div style="max-height: 400px;  overflow-y: auto;">
        <Datatable
              v-if="state.gObjectGroup && state.gObjectGroup.length > 0"
              :table-data="state.gObjectGroup"
              :table-header="tableHeaderObjGroup"
              :enable-items-per-page-dropdown="false"
              :rows-per-page="100"
              empty-table-text="Aucune ligne à afficher."
              :key="state.loadingDatatableObjGroup"
              class="table-small-td"
              @click-function="selectLineObjGroup($event)"
            >
              <template v-slot:cell-name="{ row: data }">
                <div class="mx-2 text-gray-900">
                  <el-checkbox @change="selectLineObjGroup(data)" v-model="data.select" :label="data.name" size="large"  class="text-gray-900" />
                </div>              
              </template>
            
              <template v-slot:cell-objectIds="{ row: data }">
                <div class="mx-2 text-gray-900">
                  {{ data.objectIds.length }} objet(s)
                </div>              
              </template>
              
            </Datatable>    
          </div> 
          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="state.drawerObjGroup = false">Annuler</el-button>
              <el-button v-if="state.gObjectGroup && state.gObjectGroup.filter((i)=>i.select).length > 0" class="btn btn-primary" @click="ajoutObjGroup()">Ajouter le (ou les) groupe(s) d'objets</el-button>
            </span>
          </template>
          
    </el-dialog>

    <el-dialog v-model="state.grpLinkNetwork" title="Réseaux liés au groupe" width="1000">
      <SearchAndFilter
              v-if="state.mInitialGroupeLinkNetwork && state.grpLinkNetwork"
              :listInitial="state.mInitialGroupeLinkNetwork"
              :filters="[]"
              @returnSearch="state.mGroupeLinkNetwork = $event"
              @returnLoadingDatatable="state.loadingDatatableGroupLink = $event"
      ></SearchAndFilter>

      <!--<el-switch v-model="state.filterLinkedGrp" size="large" active-text="N'afficher que les réseaux liés" inactive-text="Afficher tous les réseaux" />-->

      <div style="max-height: 400px;  overflow-y: auto;">
        <Datatable
              v-if="state.mGroupeLinkNetwork && state.mGroupeLinkNetwork.length > 0"
              :table-data="state.mGroupeLinkNetwork"
              :table-header="tableHeaderGroupLink"
              :enable-items-per-page-dropdown="false"
              :rows-per-page="100"
              empty-table-text="Aucune ligne à afficher."
              :key="state.loadingDatatableGroupLink"
              class="table-small-td"
              @click-function="selectLineGroupLink($event)"
            >
              <template v-slot:cell-net_name="{ row: data }">
                <div class="mx-2 text-gray-900">
                  <el-checkbox @change="selectLineGroupLink(data)" v-model="data.xlien" :label="data.net_name" size="large"  class="text-gray-900" />
                </div>              
              </template>
            
              <template v-slot:cell-net_id="{ row: data }">
                <div class="mx-2 text-gray-900">
                  {{ data.net_id }}
                </div>              
              </template>
              
            </Datatable>    
          </div> 
          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="state.grpLinkNetwork = false">Annuler</el-button>
              <el-button class="btn btn-primary" @click="editGroupeLinkedNetwork()">Changer la liste des réseaux liés</el-button>
            </span>
          </template>
          
    </el-dialog>

    <el-dialog v-model="state.drawerAddEditGrp" title="Saisir le nom du groupe">
          <el-input v-model="state.currentGrp.name" autocomplete="off" />
          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="state.drawerAddEditGrp = false">Annuler</el-button>
              <el-button class="btn btn-primary" @click="addEditGrp()">Editer le groupe</el-button>
            </span>
          </template>
          
    </el-dialog>

  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import { ElMessageBox } from 'element-plus'

  import { ElNotification } from 'element-plus'


  import mAxiosApi from "@/api";
  import draggable from 'vuedraggable'

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter, draggable},
    props: {
      idNetwork: String,
      editMode: Boolean,
    },
    setup(props) {

    const store = useStore();
    const state = reactive({
        rules : [] as any,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [],
        loadingDatatable: 0,       
        editMode : false,
        displayBtns: false,
        errorsTests : "" as any,
        drag : false,
        currentSource : "",
        currentIDLng : "",

        gVlans: [] as any,
        gVlansInit: [] as any,
        loadingDatatableVLAN : 0,
        drawerVLAN : false,

        gObjects:[] as any,
        gObjectsInit: [] as any,
        loadingDatatableObj : 0,
        drawerObj : false,

        gObjectGroup: [] as any,
        gObjectsGroupInit: [] as any,
        loadingDatatableObjGroup : 0,
        drawerObjGroup : false,

        displayLinks : false,
        mNetworksLink : [] as any,
        applyLinks : false,
        linksStep : 0,
        currentLink : {} as any,

        groupeFirewall : [] as any,
        specRules: false,
        displayGrp: { id:null, name:''},

        drawerAddEditGrp: false,
        currentGrp: { seq:0, name:''},

        grpLinkNetwork: false,
        mGroupeLinkNetwork: [] as any,
        mInitialGroupeLinkNetwork: [] as any,
        loadingDatatableGroupLink: 0,
        filterLinkedGrp: true,

    });

    let tableHeader = ref([]) as any;
    
    if (props.editMode) {
      tableHeader = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Type", key: "policy", sortable: false},
        { name: "Désignation", key: "comment", sortable: false,},
        { name: "Protocol", key: "protocol", sortable: false,},
        { name: "Règles - Sources / Destination", key: "srcCidr", sortable: false,},
        { name: "", key: "action", sortable: false,},
      ]);
    }else{
      tableHeader = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Type", key: "policy", sortable: false},
        { name: "Désignation", key: "comment", sortable: false,},
        { name: "Protocol", key: "protocol", sortable: false,},
        { name: "Source Port", key: "srcPort", sortable: false,},
        { name: "Source CIDR", key: "srcCidr", sortable: false,},
        { name: "Destination Port", key: "destPort", sortable: false,},
        { name: "Destination CIDR", key: "destCidr", sortable: false,},
        { name: "", key: "action", sortable: false,},
      ]);
    }

    const openAddEditGrp = (id) => {
      state.currentGrp.seq = id;
      if(id) {
        console.log(state.groupeFirewall.filter((item: any) =>item.grp_seq == id));
        state.currentGrp.name = state.groupeFirewall.filter((item: any) =>item.grp_seq == id)[0].grp_label
      }else {
        state.currentGrp.name = '';
      }
      state.drawerAddEditGrp = true;
    };

    const addEditGrp = async() => {

      if(!state.currentGrp.name) {
        ElNotification({ title: 'Erreur', message: 'Veuillez saisir le libellé svp', type: 'error',})
        return false;
      }

      const form = JSON.stringify(state.currentGrp);
      await mAxiosApi.post("/addEdit_Grp_firewall?network_id=" + props.idNetwork , form, { headers: {   'Content-Type': 'application/json'  }  });
      state.drawerAddEditGrp = false;
      state.myList=[];
      buildList();
    };

    // GROUP LINK NETWORK
      const tableHeaderGroupLink = ref([
          { name: "Nom", key: "net_name", sortable: false},
          { name: "Id", key: "net_id", sortable: false,},
          { name: "", key: "xlien", sortable: false,},  
      ]);

      const displayGrpLinkNetwork = async() => {
        const mGroupeLinkNetwork = await getAxios("/groupeLinkNetwork?source_id=" + props.idNetwork+"&groupe="+state.displayGrp.id);

        mGroupeLinkNetwork.forEach(function(item) {
          item.xlien = item.xlien > 0;
        });

        state.mGroupeLinkNetwork = mGroupeLinkNetwork
        state.mInitialGroupeLinkNetwork =  state.mGroupeLinkNetwork;
        state.loadingDatatableGroupLink++;
        state.grpLinkNetwork = true;
      };

      const selectLineGroupLink = (data) => {
        data.xlien = !data.xlien;
        state.mGroupeLinkNetwork.filter((item: any) =>item.net_id == data.net_id)[0].xlien = !state.mGroupeLinkNetwork.filter((item: any) =>item.net_id == data.net_id)[0].xlien;
      };

      const editGroupeLinkedNetwork= async() => {
        const form = JSON.stringify({listNetworks : state.mGroupeLinkNetwork});
        await mAxiosApi.post("/editGrpLinkNetwork?groupe=" + state.displayGrp.id, form, { headers: {   'Content-Type': 'application/json'  }  });
        state.displayBtns = true;
        state.grpLinkNetwork = false;
      }
      const testFilterGRP = (el) => {
        return !(el.knum_groupe==state.displayGrp.id || state.displayGrp.id=='all');
      };
      const returnOpacityFilterGRP = (el) => {
        if (el.knum_groupe==state.displayGrp.id || state.displayGrp.id=='all') {
          return 1;
        }else {
          return 0.4;
        }
      };
      

    const tableFilters = ref([
      { name: "Type", key: "policy", label: "policy", },
    ]);

    const fieldCIDR = (data) => {
      const elements = data.split(',');
      const transformedElements = elements.map(element => {
        const trimmedElement = element.trim();
        if (trimmedElement.startsWith('GRP(')) {
          const matchEl = state.gObjectsGroupInit.filter((item: any) => item.id == trimmedElement.match(/GRP\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--info el-tag--plain mx-1 mt-1'>" + matchEl.name + "</div>";
        } else if (trimmedElement.startsWith('OBJ(')) {
          const matchEl = state.gObjectsInit.filter((item: any) =>item.id == trimmedElement.match(/OBJ\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--warning el-tag--plain mt-1'>" + matchEl.name + "</div>";
        } else if (trimmedElement.startsWith('VLAN(')) {
          const matchEl = state.gVlansInit.filter((item: any) =>item.id == trimmedElement.match(/VLAN\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--large el-tag--light mx-1 mt-1'>" + matchEl.name + "</div>";
        }
        return "<div class='el-tag el-tag--primary el-tag--plain mx-1 mt-1'>" + element + "</div>";
      });
      const formattedString = transformedElements.join('<br>');
      return formattedString;
    };


    const fieldCIDREdit = (data) => {
      if (!data) return;
      const elements = data.split(',');
      const transformedElements = elements.map(element => {
        const trimmedElement = element.trim();
        // Extrait le numéro entre les parenthèses si présent
        const rawValue = trimmedElement.match(/\((\d+)\)/)?.[1] || '';
        
        if (trimmedElement.startsWith('GRP(')) {
          const matchEl = state.gObjectsGroupInit.filter((item) => item.id == rawValue)[0];
          return { type: 'GRP', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        } else if (trimmedElement.startsWith('OBJ(')) {
          const matchEl = state.gObjectsInit.filter((item) => item.id == rawValue)[0];
          return { type: 'OBJ', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        } else if (trimmedElement.startsWith('VLAN(')) {
          const matchEl = state.gVlansInit.filter((item) => item.id == rawValue)[0];
          return { type: 'VLAN', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        }
        return { type: 'FREE', value: trimmedElement, rawValue: trimmedElement };
      });
      return transformedElements;
    }

    onMounted(async () => {  
      if (!store.getters.getOrg.idOrg) return false;
      state.groupeFirewall
      buildList();
    });

    const buildList = async () => {
        
        state.displayBtns=false;
        state.loaderEnabled = true;
        state.editMode = props.editMode;

        state.errorsTests = "";
        
        const mRules = await getAxios("/firewall?edit_mode="+state.editMode+"&network_id=" + props.idNetwork);
        state.myList = mRules;
        let mID = 0;
        state.myList.forEach(function(item) {
          item.id = mID;
          item.action = mID;
          mID += 1;
        });
        state.initialMyList = state.myList;

        const mGroupeFirewall = await getAxios("/groupeFirewall?network_id=" + props.idNetwork);
        const mObjects = await getAxios("/policiesObjects?organization_id=" + store.getters.getOrg.idOrg);
        const mObjectGroup = await getAxios("/policiesObjectsGroup?organization_id=" + store.getters.getOrg.idOrg + "&network_id=" + props.idNetwork);
        const mVlans = await getAxios("/vlans?network_id=" + props.idNetwork);
        const mNetworksLink = await getAxios("/networksLink?source_id=" + props.idNetwork);

        state.groupeFirewall = mGroupeFirewall;
        state.mNetworksLink = mNetworksLink;

        mObjects.forEach(element => {
          element.select = false;
        });
        state.gObjects = mObjects;
        state.gObjectsInit = mObjects;

        mObjectGroup.forEach(element => {
          element.select = false;
        });
        state.gObjectGroup = mObjectGroup;
        state.gObjectsGroupInit = mObjectGroup;

        mVlans.reponse.forEach(element => {
          element.select = false;
        });
        state.gVlans = mVlans.reponse;
        state.gVlansInit = mVlans.reponse;
        
        state.loaderEnabled = false;
        state.loadingDatatable += 1;
        
      }

      const recalcLngs = () => {
        let mID = 0;
        state.myList.forEach(function(item) {
          item.id = mID;
          item.action = mID;
          mID += 1;
        });
      };

      const addEmptyRowAtStart = () => {

        state.displayBtns = true;
        
        const myList = state.myList;
        if (myList.length === 0) {
            return;
        }
        const keys = Object.keys(myList[0]);
        const emptyObject = keys.reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        myList.unshift(emptyObject);

        myList[0].policy = 'allow';
        myList[0].protocol = 'any';
        myList[0].srcCidr = "";
        myList[0].destCidr = "";
        myList[0].syslogEnabled = false;

        if (state.displayGrp.id){
           myList[0].knum_groupe = state.displayGrp.id;
        }else {
          myList[0].knum_groupe = null;
        }

        state.myList = myList;

        recalcLngs();

        state.loadingDatatable += 1;
      };

      const delLignes = (id) => {
        state.displayBtns = true;
        state.myList.splice(state.myList.findIndex(el => el.id == id), 1)
        
        recalcLngs();
                
      };

      
      const dropRule = async () => {
        recalcLngs();
        state.displayBtns=true;
      };


      const delElement = (source, idLng, rawValue, el) => {

        if (testFilterGRP(el)) return false;

        state.displayBtns = true;
        let mStr = "";
        if (source == 'SRC') mStr = state.myList[idLng].srcCidr;
        if (source == 'DEST') mStr = state.myList[idLng].destCidr;

        const elements = mStr.split(',');
        let strOut = "";
        elements.forEach(function(item) {
          if(item != rawValue) strOut += "," + item
        });
        
        if (source == 'SRC') state.myList[idLng].srcCidr = strOut.slice(1);
        if (source == 'DEST') state.myList[idLng].destCidr = strOut.slice(1);

        state.loadingDatatable += 1;


      };

    // ADD VLAN 
    const tableHeaderVLAN = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Nom", key: "name", sortable: false},
        { name: "IP", key: "applianceIp", sortable: false,},
        { name: "Subnet", key: "subnet", sortable: false,},
      ]);

      const selectLineVLAN = (data) => {
        data.select = !data.select;
        state.gVlans.filter((item: any) =>item.id == data.id)[0].select = !state.gVlans.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptVLAN = (source,idLng) => {
        state.gVlans.forEach(element => {
          element.select = false;
        });

        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerVLAN = true;
      }

      const ajoutVLAN = () => {
        state.gVlans.forEach(function(item) {
          if (item.select) {
            const value = "VLAN("+ item.id +").*";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });
        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerVLAN = false;
      }


      // ADD OBJECTS
      const tableHeaderObj = ref([
        { name: "Nom", key: "name", sortable: false},
        { name: "CIDR", key: "cidr", sortable: false,},
        { name: "Category", key: "category", sortable: false,},
      ]);

      const selectLineObj = (data) => {
        data.select = !data.select;
        state.gObjects.filter((item: any) =>item.id == data.id)[0].select = !state.gObjects.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptObj = (source,idLng) => {
        state.gObjects.forEach(element => {
          element.select = false;
        });
        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerObj = true;
      }

      const ajoutObj = () => {
        state.gObjects.forEach(function(item) {
          if (item.select) {
            const value = "OBJ("+ item.id +")";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            console.log( state.myList[state.currentIDLng].srcCidr )
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });

        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerObj = false;
      }


      // ADD GROUP OBJECTS
      const tableHeaderObjGroup = ref([
        { name: "Nom", key: "name", sortable: false},
        { name: "Objets", key: "objectIds", sortable: false,},
      ]);

      const selectLineObjGroup = (data) => {
        data.select = !data.select;
        state.gObjectGroup.filter((item: any) =>item.id == data.id)[0].select = !state.gObjectGroup.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptObjGroup = (source,idLng) => {
        state.gObjectGroup.forEach(element => {
          element.select = false;
        });
        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerObjGroup = true;
      }


      const ajoutObjGroup = () => {
        state.gObjectGroup.forEach(function(item) {
          if (item.select) {
            const value = "GRP("+ item.id +")";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            console.log( state.myList[state.currentIDLng].srcCidr )
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });

        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerObjGroup = false;
      }

      const promptIPV4 = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un CIDR IPv4 valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler',
          inputPattern: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))?$/,
          inputErrorMessage: 'CIDR IPv4 invalide',
        })
        .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }

      const promptIPV6 = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un CIDR IPv6 valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler',
          inputPattern: /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/(12[0-8]|1[01][0-9]|[1-9]?[0-9]))?$/,
          inputErrorMessage: 'CIDR IPv6 invalide',
        })
          .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }

      const promptFree = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un domaine valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler'
        })
          .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }


      // VALID CHANGES FIREWALL
      const validFirewall = async () => {
        state.loaderEnabled = true;
        state.errorsTests = "";

        const form = JSON.stringify(state.myList);
        const response = await mAxiosApi.post("/update_firewall?source=1&network_id=" + props.idNetwork , form, { headers: {   'Content-Type': 'application/json'  }  });
          
        state.loaderEnabled = false;

        if (response.data.errors) {         
          state.errorsTests = response.data.errors;

        }else {
          applyFirewallToLinks();
        }


      };


      const applyFirewallToLinks = async () => {

        state.loaderEnabled = false;
        state.errorsTests = "";

        state.applyLinks = true;
        state.linksStep = 0;
        
        const form = JSON.stringify(state.myList);

        const maxConcurrentRequests = 9;
        const links = state.mNetworksLink.slice(); 
        const totalLinks = links.length; 
        const results: any[] = [];

        let processedLinks = 0;

        while (links.length > 0) {
          const batch = links.splice(0, maxConcurrentRequests);
          const batchPromises = batch.map(async (link) => {
            try {
              const response = await mAxiosApi.post("/update_firewall?network_id=" + link.net_id , form, { headers: {   'Content-Type': 'application/json'  }  });

              processedLinks++;
              state.linksStep = Math.round((processedLinks / totalLinks) * 100);

              return response.data;
            } catch (error) {
              console.error(`Une erreur s'est produite lors de la requête Axios pour ${link}:`, error);
              return null;
            }
          });

          const batchResults: any = await Promise.all(batchPromises);
          results.push(...batchResults);

          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        ElNotification({ title: 'Succés', message: 'Règles appliquées avec succées sur tous les réseaux liés', type: 'success',})

        state.displayBtns=false;
        state.applyLinks = false;
        state.displayBtns = false;


      };

     return {
        state,
        store,
        tableHeader,
        tableFilters,
        fieldCIDR,
        fieldCIDREdit,
        delElement,
        addEmptyRowAtStart,
        delLignes,
        buildList,
        promptIPV4,
        promptIPV6,
        promptFree,
        validFirewall,
        dropRule,
        promptVLAN,
        tableHeaderVLAN,
        selectLineVLAN,
        ajoutVLAN,
        tableHeaderObj,
        selectLineObj,
        promptObj,
        ajoutObj,
        tableHeaderObjGroup,
        selectLineObjGroup,
        promptObjGroup,
        ajoutObjGroup,
        addEditGrp,
        openAddEditGrp,
        displayGrpLinkNetwork,
        tableHeaderGroupLink,
        selectLineGroupLink,
        editGroupeLinkedNetwork,
        testFilterGRP,
        returnOpacityFilterGRP
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>